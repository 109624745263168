import React from "react";
import cn from "classnames";

import { Props } from "./types";

import styles from "./style.module.scss";

import WalletIcon from "src/assets/icons/wallet.svg";

export const PopupError = (props: Props) => (
  <div className={cn(styles.popup, { [styles.show]: props.show })}>
    <div className={styles.icon}>
      {props.wallet && (
        <img className={styles.wallet} src={WalletIcon} draggable="false" />
      )}
    </div>
    <span>{props.text}</span>
  </div>
);
